<template>
  <div>
    <label v-if="labelName" class="form-label">
      {{ labelName }}
    </label>

    <div class="row align-top">
      <div class="position-relative">
        <google-input
        :read-only="readOnly"
        @keyup="showAutoComplete(true)"
        @keyup.up="moveOption('up')"
        @keyup.down="moveOption('down')"
        @focusin="showAutoComplete(true)"
        @focusout="showAutoComplete(false)"
        @keyup.enter="autocomplete"
        :modelValue="selectedOption"
        @update:modelValue="newValue => selectedOption = newValue"
        @input="$emit('update:modelValue', selectedOption)"
        input-type="text"
        placeholder-name="Search for..."
        >
      </google-input>

      <div v-if="!isLoading && isAutocompleteShow" class="group-list">
        <p
        @mousedown="clickSelection(option)"
        :class="{
          'selected': selectedOption === option,
        }"
        class="is-clickable p-2 rounded-3 border-bottom"
        v-for="(option, index) in autocompletes"
        :key="index"
        >
        {{ option }}
      </p>
    </div>

    <div v-else-if="isLoading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    
  </div>
</div>
</div>
</template>

<script>
  import GoogleInput from './GoogleInput'

  export default {
    name: 'GoogleSelect',

    components: {GoogleInput},

    props: {
      value: {
        required: false
      },
      labelName: {
        type: String,
        required: false,
        default: null
      },
      searchIsDisplayed: {
        type: Boolean,
        default: true
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      autocompletes: {
        type: Array,
        required: false,
        default: function () {
          return []
        }
      },
      modelValue: {
        required: false
      },
      selectedValue: {
        required: false
      }
    },

    emits: ['update:modelValue', 'update:selectedValue'],

    data() {
      return {
        selectOptions: this.autocompletes,
        sortedOptions: this.autocompletes,
        selectedOption: null,
        isLoading: false,
        isAutocompleteShow: false,
      }
    },

    methods: {
      selectCurrentOption(value) {
        this.selectedOption = value;
        this.$emit('update:selectedValue', value);
      },

      clickSelection(value) {
        this.selectCurrentOption(value);
        this.searchTerm = value;
      },

      moveOption(direction) {
        const currentOption = this.autocompletes.find(
          (option) => option === this.selectedOption
          )
        const index = this.autocompletes.findIndex(
          (option) => option === currentOption
          )

        if (direction === 'up') {
          if (index > 0) {
            this.selectCurrentOption(this.autocompletes[index - 1]);
          }
        }

        if (direction === 'down') {
          if (index !== this.autocompletes.length - 1) {
            this.selectCurrentOption(this.autocompletes[index + 1]);
          }
        }
      },

      showAutoComplete(show) {
        this.isAutocompleteShow = show;
      },

      autocomplete() {
        this.showAutoComplete(false);
        this.searchTerm = this.selectedOption;
      }
    }
  }
</script>

<style scoped>

</style>
